import React from 'react';

export default function ProjectGrid(props) {
    const handleSelection = (project) => {
        if (project['status'] === "READY") {
            props.onClick(project['project_id']);
        }
    };

    return (
        <div className="project-grid">
            {
                props.projects.map((project) =>
                    <div key={project['project_id']} className="project-grid-item" onClick={() => handleSelection(project)}>
                        <div className="project-grid-item-image-container">
                            <img className="project-grid-item-image" src="audio-description.png" alt="A man smiling with his eyes closed as he listens to headphones when a TV screen is playing in front of him." />
                            <div className="project-grid-item-status">
                                {
                                    project['status'] === "PROCESSING" && (
                                        <>
                                            <div className="project-grid-item-processing-circle" />
                                            <p className="black-regular-5-text">PROCESSING</p>
                                        </>
                                    )
                                }
                                {
                                    project['status'] === "READY" && (
                                        <>
                                            <div className="project-grid-item-ready-circle" />
                                            <p className="green-regular-5-text">READY</p>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <div className="project-grid-item-metadata">
                            <h5 className="black-header-4-text">{project['project_name']}</h5>
                            <h6 className="black-regular-5-text">Last updated 2 days ago</h6>
                        </div>
                    </div>
                )
            }
        </div>
    );
}