import React, { useState } from 'react';
import { Navbar, Footer } from '../components';

export default function UnauthenticatedHome(props) {
    const [selectedVideo, setSelectedVideo] = useState(0);
    const videos = ["https://d15cs9ijqrhebi.cloudfront.net/apple_keynote.mp4", "https://d15cs9ijqrhebi.cloudfront.net/apple_1984.mp4", "https://d15cs9ijqrhebi.cloudfront.net/mr_bean.mp4", "https://d15cs9ijqrhebi.cloudfront.net/planet_earth_3_trailer.mp4"]
    const videoTypes = ["Music Video", "Advertisement", "Comedy Sketch", "Trailer"];

    const handleSelection = (index) => {
        setSelectedVideo(index);
    };

    return (
        <div className="base-page blue-gradient">
            <Navbar />
            <div className="unauth-home-content">
                <div className="unauth-home-hero-section">
                    <div className="unauth-home-hero-section-main-text-container">
                        <h1 className="black-header-1-text">Visual stories, made audible to all.</h1>
                    </div>
                    <div className="unauth-home-hero-section-secondary-text-container">
                        <p className="black-regular-4-text">Unlock new audiences, ensure compliance with accessibility regulations, and differentiate your brand with the world's first automated audio description service.</p>
                    </div>
                    <div className="unauth-home-hero-section-actions">
                        {/* <div className="create-project-button" onClick={createProject}>
                            Create project
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 6l6 6l-6 6"></path>
                            </svg>
                        </div> */}
                        <a className="create-project-button" href="https://forms.gle/zX94zqPUpUsi1YPw9" target="_blank">
                            Get in touch
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 6l6 6l-6 6"></path>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="unauth-home-demo-content-section">
                    <div className="unauth-home-demo-video-section">
                        <video className="unauth-home-demo-video" src={videos[selectedVideo]} controls />
                    </div>
                    <div className="unauth-home-demo-video-tabs">
                        {
                            videoTypes.map((videoType, index) =>
                                <div key={index} className={`unauth-home-demo-video-tab ${selectedVideo === index ? "active" : ""}`} onClick={() => handleSelection(index)}>
                                    <h5 className={selectedVideo === index ? "black-header-4-text" : "gray-regular-4-text"}>{videoType}</h5>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="unauth-home-definition-content-section">
                    <h4 className="blue-header-5-text">What is audio description?</h4>
                    <h2 className="black-header-2-text">The next frontier of media accessibility</h2>
                    <p className="black-regular-4-text">Audio description transforms visual content into vivid auditory experiences, enabling visually impaired audiences to enjoy every detail of a story. It's storytelling through sound, where every visual element is articulated into engaging audio narratives.</p>
                </div>
                <div className="unauth-home-products-content-section">
                    <div className="unauth-home-service-content-section">
                        <h4 className="white-header-5-text">For Companies With Videos</h4>
                        <h2 className="white-header-2-text">Get your videos described quickly, with confidence.</h2>
                        <div className="unauth-home-service-reasons-content-section">
                            <div className="unauth-home-service-reason">
                                <div className="unauth-home-service-reason-checkbox">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="10" height="10" viewBox="0 0 24 24" strokeWidth="4" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                </div>
                                <p className="white-regular-4-text">AI generated descriptions, mixed beautifully into any video.</p>
                            </div>
                            <div className="unauth-home-service-reason">
                                <div className="unauth-home-service-reason-checkbox">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="10" height="10" viewBox="0 0 24 24" strokeWidth="4" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                </div>
                                <p className="white-regular-4-text">Industry-leading turnaround time and price.</p>
                            </div>
                            <div className="unauth-home-service-reason">
                                <div className="unauth-home-service-reason-checkbox">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="10" height="10" viewBox="0 0 24 24" strokeWidth="4" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                </div>
                                <p className="white-regular-4-text">Simple interface to make script changes and adjustments to the voice.</p>
                            </div>
                            <div className="unauth-home-service-reason">
                                <div className="unauth-home-service-reason-checkbox">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="10" height="10" viewBox="0 0 24 24" strokeWidth="4" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                </div>
                                <p className="white-regular-4-text">Security built in to ensure your media is safe.</p>
                            </div>
                        </div>
                        <a className="unauth-home-service-contact-sales-button" href="https://forms.gle/zX94zqPUpUsi1YPw9" target="_blank">
                            Get in touch
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 6l6 6l-6 6"></path>
                            </svg>
                        </a>
                    </div>
                    <div className="unauth-home-api-content-section">
                        <h4 className="blue-header-5-text">For Audio Description Vendors</h4>
                        <h2 className="black-header-2-text">The building blocks to accelerate your your product.</h2>
                        <div className="unauth-home-service-reasons-content-section">
                            <div className="unauth-home-service-reason">
                                <div className="unauth-home-api-reason-checkbox">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="10" height="10" viewBox="0 0 24 24" strokeWidth="4" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                </div>
                                <p className="black-regular-5-text">Modular AI solutions for script generation, scheduling, voicing, and mixing.</p>
                            </div>
                            <div className="unauth-home-service-reason">
                                <div className="unauth-home-api-reason-checkbox">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="10" height="10" viewBox="0 0 24 24" strokeWidth="4" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                </div>
                                <p className="black-regular-5-text">Easy API integrations with guaranteed uptime and turnaround in minutes.</p>
                            </div>
                            <div className="unauth-home-service-reason">
                                <div className="unauth-home-api-reason-checkbox">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="10" height="10" viewBox="0 0 24 24" strokeWidth="4" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                </div>
                                <p className="black-regular-5-text">Flexibility to make script changes and adjustments to the voice.</p>
                            </div>
                            <div className="unauth-home-service-reason">
                                <div className="unauth-home-api-reason-checkbox">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="10" height="10" viewBox="0 0 24 24" strokeWidth="4" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                </div>
                                <p className="black-regular-5-text">24/7 customer support</p>
                            </div>
                            <div className="unauth-home-service-reason">
                                <div className="unauth-home-api-reason-checkbox">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="10" height="10" viewBox="0 0 24 24" strokeWidth="4" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                </div>
                                <p className="black-regular-5-text">Security built in to ensure your customers' media is safe.</p>
                            </div>
                        </div>
                        <a className="unauth-home-api-contact-sales-button" href="https://forms.gle/zX94zqPUpUsi1YPw9" target="_blank">
                            Get in touch
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 6l6 6l-6 6"></path>
                            </svg>
                        </a>
                    </div>
                </div>
                {/* <div className="unauth-home-benefits-content-section">
                    <h4 className="blue-header-5-text">The benefits of audio description</h4>
                    <h2 className="black-header-2-text">The next frontier of media accessibility</h2>
                    <div className="unauth-home-benefits-cards">
                        <div className="unauth-home-benefits-card">
                            <div className="unauth-home-benefits-card-header">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-accessible" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                    <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1"></path>
                                    <circle cx="12" cy="7.5" r=".5" fill="currentColor"></circle>
                                </svg>
                                <h4 className="black-header-4-text">Access new audiences</h4>
                            </div>
                        </div>
                        <div className="unauth-home-benefits-card">
                            <div className="unauth-home-benefits-card-header">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-accessible" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                    <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1"></path>
                                    <circle cx="12" cy="7.5" r=".5" fill="currentColor"></circle>
                                </svg>
                                <h4 className="black-header-4-text">Access new audiences</h4>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    );
}