import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ProjectFooter, ProjectNavbar } from '../components';

export default function Project(props) {
    let params = useParams();
    
    const [project, setProject] = useState({});
    const [descriptions, setDescriptions] = useState([]);

    useEffect(() => {
        setProject({'project_id': '1', 'project_name': 'Apple Keynote Introduction'});
        setDescriptions([
            {'start_time': "00:00:01", 'end_time': "00:04:14", 'text': "An open garage reveals musical instruments and recording equipment."},
            {'start_time': "00:04:64", 'end_time': "00:09:32", 'text': "A person stands by a mixing console inside the garage studio."},
            {'start_time': "00:09:82", 'end_time': "00:12:80", 'text': "A microphone is positioned near a laptop on a desk."},
            {'start_time': "00:13:30", 'end_time': "00:16:68", 'text': "He begins editing music on the computer in the studio."},
            {'start_time': "00:17:18", 'end_time': "00:20:12", 'text': "He presses the play button to start the audio playback."}
        ])
    }, []);

    return (
        <div className="project-base">
            <ProjectNavbar title={project['project_name']}/>
            <div className="project-content">
                <div className="project-video-track-container">
                    <video className="project-video" src="apple_keynote.mp4" controls />
                </div>
                <div className="project-descriptions">
                    {
                        descriptions.map((description) => 
                            <div className="project-description">
                                <p className="black-header-4-text">{description['start_time'] + " - " + description['end_time']}</p>
                                <p className="black-regular-5-text">{description['text']}</p>
                            </div>
                        )
                    }
                </div>
            </div>
            <ProjectFooter />
        </div>
    );
}