import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';

import { ScrollToTop } from './components';
import { Home, Projects, Project } from './pages';

axios.defaults.baseURL = 'http://127.0.0.1:80';

export default function App(props) {
  return (
		<BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='p'>
            <Route index element={<Projects />} />
            <Route path=':projectId' element={<Project />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
	);
}