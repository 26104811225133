import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectFooter, ProjectNavbar } from '../components';
import ProjectGrid from '../components/ProjectGrid';

export default function Projects(props) {
    const navigate = useNavigate();

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setProjects([
            {'project_id': '1', 'project_name': 'Super Bowl', 'status': 'PROCESSING'},
            {'project_id': '2', 'project_name': 'Super Bowl 2', 'status': 'READY'}
        ]);
    }, []);

    const handleSelection = (projectId) => {
        navigate('/p/' + projectId);
    };

    return (
        <div className="base-page">
            <ProjectNavbar />
            <ProjectGrid projects={projects} onClick={handleSelection} />
            <ProjectFooter />
        </div>
    );
}