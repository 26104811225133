import React from 'react';

export default function Navbar(props) {
    return (
        <div className="navbar">
            <div className="navbar-container">
                <div className="navbar-left-container">
                    <div className="navbar-logo-container">
                        <div className="navbar-logo">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-feather" width="27" height="27" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M4 20l10 -10m0 -5v5h5m-9 -1v5h5m-9 -1v5h5m-5 -5l4 -4l4 -4"></path>
                                <path d="M19 10c.638 -.636 1 -1.515 1 -2.486a3.515 3.515 0 0 0 -3.517 -3.514c-.97 0 -1.847 .367 -2.483 1m-3 13l4 -4l4 -4"></path>
                            </svg>
                        </div>
                        <h5 className="black-header-3-text">Autoscribe</h5>
                    </div>
                    {/* <div className="navbar-menu">
                        <div className="navbar-menu-item">
                            <div className="navbar-menu-item-button">Products</div>
                        </div>
                        <div className="navbar-menu-item">
                            <div className="navbar-menu-item-button">Solutions</div>
                        </div>
                        <div className="navbar-menu-item">
                            <div className="navbar-menu-item-button">Resources</div>
                        </div>
                        <div className="navbar-menu-item">
                            <div className="navbar-menu-item-button">Pricing</div>
                        </div>
                    </div> */}
                </div>
                <div className="navbar-right-container">
                    <a className="create-project-button" href="https://forms.gle/zX94zqPUpUsi1YPw9" target="_blank">
                        Get in touch
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6l6 6l-6 6"></path>
                        </svg>
                    </a>
                    {/* <div className="create-project-button" onClick={props.createProject}>
                        Create project
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6l6 6l-6 6"></path>
                        </svg>
                    </div> */}
                </div>
            </div>
        </div>
    );
}