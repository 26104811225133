import React, { useEffect, useState } from 'react';

import { useInput } from '../hooks';
import UnauthenticatedHome from './UnauthenticatedHome';

export default function Home(props) {
    const [projects, setProjects] = useState([]);
    const [createProjectModal, setCreateProjectModal] = useState(false);

    const { value: projectName, bind: bindProjectName, reset: resetProjectName } = useInput("");
    const [projectNameError, setProjectNameError] = useState(null);

    const { value: url, bind: bindUrl, reset: resetUrl } = useInput("");
    const [urlError, setUrlError] = useState(null)

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setProjectNameError(null);
        setUrlError(null);
    }, [projectName, url]);

    // useEffect(() => {
    //     axios.get('http://localhost:80/projects').then((response) => {
    //         console.log(response);
	// 		setProjects(response.data);
	// 	}).catch((error) => {
	// 		console.log(error);
	// 	});
    // }, []);

    const activateCreateProjectModal = () => {
        setCreateProjectModal(true);
    };

    const handleSubmit = () => {
        var valid = true;
        if (projectName === "") {
            setProjectNameError("Project name is required");
            valid = false;
        }
        if (url === "") {
            setUrlError("YouTube URL is required")
            valid = false;
        }
        if (valid) {
            setLoading(true);
        }
    };
    
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    const exit = (e) => {
        if (e === undefined || e.target === e.currentTarget) {
            setCreateProjectModal(false);
            setLoading(false);
            setProjectNameError(null);
            setUrlError(null);
        }
    };

    return (
        <UnauthenticatedHome />
    );

    // return (
    //     <>
    //         {
    //             createProjectModal && (
    //                 <Modal 
    //                     detail={
    //                         <div className="create-project-modal-base">
    //                             <div className="form-group">
    //                                 <h2 className="black-header-5-text">Enter a project name</h2>
    //                                 <div className="form-level">
    //                                     <div className="form-text-field">
    //                                         <input className={projectNameError === null ? "text-input-box" : "text-input-box red"} type="text" placeholder="Your project name" onKeyDown={(e) => handleEnter(e)} {...bindProjectName} />
    //                                         {
    //                                             projectNameError !== null && (
    //                                                 <div className="create-project-modal-error-message">
    //                                                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                                         <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    //                                                         <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
    //                                                         <path d="M12 8v4"></path>
    //                                                         <path d="M12 16h.01"></path>
    //                                                     </svg>
    //                                                     <p className="red-regular-5-text">{projectNameError}</p>
    //                                                 </div>
    //                                             )
    //                                         }
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                             <div className="form-group">
    //                                 <h2 className="black-header-5-text">Enter a YouTube URL</h2>
    //                                 <div className="form-level">
    //                                     <div className="form-text-field">
    //                                         <input className={urlError === null ? "text-input-box" : "text-input-box red"} type="text" placeholder="Your project name" onKeyDown={(e) => handleEnter(e)} {...bindUrl} />
    //                                         {
    //                                             urlError !== null && (
    //                                                 <div className="create-project-modal-error-message">
    //                                                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
    //                                                         <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    //                                                         <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
    //                                                         <path d="M12 8v4"></path>
    //                                                         <path d="M12 16h.01"></path>
    //                                                     </svg>
    //                                                     <p className="red-regular-5-text">{urlError}</p>
    //                                                 </div>
    //                                             )
    //                                         }
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     }
    //                     title={"Create a project"}
    //                     action={"Continue"}
    //                     handleAction={handleSubmit}
    //                     loading={loading}
    //                     exit={exit} />
    //             )
    //         }
    //         <div className="navbar">
    //             <div className="navbar-container">
    //                 <p className="black-header-3-text">Autoscribe</p>
    //                 <div className="navbar-center-container">
    //                     <p className="black-header-5-text">All projects</p>
    //                 </div>
    //                 <div className="navbar-right-container">
    //                     <div className="create-project-button" onClick = {activateCreateProjectModal}>Create project</div>
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="project-grid">
    //             {
    //                 projects.map((project) =>
    //                     <div key={project['project_id']} className="project-grid-item">
    //                         <div className="project-grid-item-image">

    //                         </div>
    //                         <p className="black-header-5-text">{project['project_name']}</p>
    //                     </div>
    //                 )
    //             }
    //         </div>
    //     </>
    // );
}